<template>
  <v-menu v-model="show" :close-on-content-click="false">
    <template v-slot:activator="{on}">
      <v-btn icon small class="ml-4" :title="$t('widgets.agenda.fields.addEvent')" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card
        min-width="350px"
        max-width="400px"
    >
      <v-toolbar
          dense
          flat
      >

        <v-toolbar-title>{{ $t('widgets.agenda.fields.addEvent') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small class="ml-2" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-toolbar>

      <v-form
          v-model="valid"
          ref="addEventForm">
        <v-card-text>

                <v-text-field
                    v-model="newEvent.name"
                    :label="$t('widgets.agenda.fields.title')"
                    :rules="ValidationRules.required"
                    validate-on-blur

                ></v-text-field>

                <v-datetime-picker v-model="newEventStart" :label="$t('widgets.agenda.fields.startDate')"
                                   :rules="applyRules ? ValidationRules.required : []"
                                   :timePickerProps="{format :'24hr'}"
                                   :datePickerProps="{locale: locale, 'first-day-of-week': '1', allowedDates: allowedDatesStart}"
                                   :dateFormat="getLocaleFormat($i18n.locale)"
                                   :ok-text="$t('app.datetimePicker.okText')"
                                   :clear-text="$t('app.datetimePicker.clearText')"
                                   :key="cheatKey+'start'"
                ></v-datetime-picker>

                <v-datetime-picker v-model="newEventEnd" :label="$t('widgets.agenda.fields.endDate')"
                                   :timePickerProps="{format :'24hr'}"
                                   :datePickerProps="{locale: locale, 'first-day-of-week': '1', allowedDates: allowedDatesEnd}"
                                   :dateFormat="getLocaleFormat($i18n.locale)"
                                   :ok-text="$t('app.datetimePicker.okText')"
                                   :clear-text="$t('app.datetimePicker.clearText')"
                                   :key="cheatKey+'end'"
                >

                </v-datetime-picker>

                <v-textarea v-model="newEvent.text" auto-grow rows="3" :label="$t('widgets.agenda.fields.addDescription')"></v-textarea>

                <p class="subtitle-1">
                  {{ $t('app.colorPicker.title')}}
                  <color-picker v-model="newEvent.color"></color-picker>
                </p>

        </v-card-text>
        <v-card-actions>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn color="success" @click="add">{{ $t('actions.save') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
import MixinValidation from "@/Mixins/ValidationRules"

export default {
  name: "AddEvent",
  mixins: [MixinValidation],
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color"),
    ColorPicker: () => import("@/Components/Commons/Inputs/Color/ColorPicker")
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    allowedDatesStart() {
      if (!this.newEventEnd) {
        return null;
      } else {
        return date => {
          let endDate = new Date(this.newEventEnd)
          let testDate = new Date(date)
          endDate.setHours(0, 0, 0)
          testDate.setHours(0, 0, 0)
          return testDate <= endDate
        }
      }
    },
    allowedDatesEnd() {
      if (!this.newEventStart) {
        return null;
      } else {
        return date => {
          let startDate = new Date(this.newEventStart)
          let testDate = new Date(date)
          testDate.setHours(0, 0, 0)
          startDate.setHours(0, 0, 0)
          return testDate >= startDate
        }
      }
    }
  },
  data() {
    return {
      show: false,
      valid: false,
      newEvent: {},
      showEditColor: false,
      applyRules: false,
      newEventStart: null,
      newEventEnd: null,
      cheatKey: null
    }
  },
  methods: {
    add: async function () {
      this.applyRules = true
      await this.sleep(1)
      if (this.$refs.addEventForm.validate()) {
        if (this.newEvent.start && this.newEvent.end) {
          if (this.newEvent.start.getDay() == this.newEvent.end.getDay()
              && this.newEvent.start.getMonth() == this.newEvent.end.getMonth()
              && this.newEvent.start.getYear() == this.newEvent.end.getYear())
          this.newEvent.timed = true
        }
        this.newEvent.start = this.newEventStart
        this.newEvent.end = this.newEventEnd
        this.$emit('add', Object.assign({}, this.newEvent))
        this.show = false
      }
    },
    close: function () {
      this.showEditColor = false
      this.show = false
      this.$emit("close")
      this.resetForm()
    },
    resetForm: function () {
      this.cheatKey = this.$uuid.v4()
      this.applyRules = false
      this.newEventStart = null
      this.newEventEnd = null
      this.initNewEvent()
    },
    initNewEvent() {
      this.$set(this.newEvent, "name", "")
      this.$set(this.newEvent, "text", "")
      this.$set(this.newEvent, "start", null)
      this.$set(this.newEvent, "end", null)
      this.$set(this.newEvent, "color", "")
    }

  },
  watch: {
    show: function (val) {
      if (val == true) {
        this.resetForm()
      }
    }
  },
  created() {
    this.initNewEvent()
  }
}
</script>

<style scoped>

</style>